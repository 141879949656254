import VerticalMenu_ComboBoxInputNavigationComponent from '@wix/thunderbolt-elements/src/components/VerticalMenuComboBox/viewer/skinComps/ComboBoxInputNavigation/ComboBoxInputNavigation.skin';
import VerticalMenu_ComboBoxInputNavigationController from '@wix/thunderbolt-elements/src/components/VerticalMenuComboBox/viewer/VerticalMenuComboBox.controller';


const VerticalMenu_ComboBoxInputNavigation = {
  component: VerticalMenu_ComboBoxInputNavigationComponent,
  controller: VerticalMenu_ComboBoxInputNavigationController
};


export const components = {
  ['VerticalMenu_ComboBoxInputNavigation']: VerticalMenu_ComboBoxInputNavigation
};

